import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';

import { createTheme } from '@mui/material';
import { endOfDay, endOfMonth, startOfDay, startOfMinute, startOfMonth, subDays } from 'date-fns';
import i18next from 'i18next';
import merge from 'lodash/merge';

import { AppContextState, Filter, FilterType, Layouts } from './types';
import { useA1, DEFAULT_LIMIT } from 'api';
import { QueryParams } from 'api/useA1';
import { CONTENT_VIEW } from 'constants/content';
import { FILTER_BY } from 'constants/filter';
import { SortDirection, SORT_DIRECTION } from 'constants/sort';
import { defaultLanguage, i18nInit } from 'i18n';
import { A1Calendar, A1Events, A1Filters } from 'interfaces/A1Results';
import { CalendarView } from 'interfaces/ICalendar';
import { IEvent } from 'interfaces/IEvent';
import { createCustomLocalizationUrl } from 'services/createUrl';
import { eventillaDate } from 'services/format';
import loadCustom from 'services/loadCustom';
import { themeEmbed } from 'theme';

const contextInitialValues: AppContextState = {
    events: [],
    contentView: CONTENT_VIEW.List,
    contentViews: [CONTENT_VIEW.List, CONTENT_VIEW.Grid, CONTENT_VIEW.Calendar],
    layouts: {
        [CONTENT_VIEW.List]: {
            filters: ['LOCATION', 'SEARCH', 'TAGS'],
            eventFields: ['NAME', 'LOCATION', 'DATE', 'REGISTRATION_BUTTON', 'LOGO'],
        },
        [CONTENT_VIEW.Grid]: {
            filters: ['LOCATION', 'SEARCH', 'TAGS'],
            eventFields: ['DESCRIPTION', 'NAME', 'LOCATION', 'DATE', 'REGISTRATION_BUTTON', 'LOGO'],
            logoHeight: 200,
        },
        [CONTENT_VIEW.Calendar]: {
            filters: ['LOCATION', 'SEARCH', 'TAGS'],
            eventFields: ['NAME', 'LOCATION', 'DATE', 'REGISTRATION_BUTTON', 'SLOTS', 'TICKETS'],
        },
    },
    setContentView: () => {
        console.error('No provider');
    },
    direction: SORT_DIRECTION.Ascending,
    setDirection: () => {
        console.error('No provider');
    },
    filterType: {
        [Filter.FilterBy]: FILTER_BY.UpcomingEvents,
        [Filter.DateRange]: [null, null],
        [Filter.Locations]: new Set(),
        [Filter.Tags]: [],
        [Filter.TextField]: null,
    },
    setFilterType: () => {
        console.error('No provider');
    },
    setLanguage: () => {
        console.error('No provider');
    },
    setEventPage: () => {
        console.error('No provider');
    },
    foundTags: new Set(), // Tags found through events/ api calls
    availableLocations: new Set(),
    datetimeFormat: 'DD MMM YYYY hh:mm',
    eventsDefaultImage: null,
    loading: true,
    error: null,
    changedLanguage: null,
    translations: [],
    organizationUri: null,
    theme: themeEmbed, //theme,
    hasMoreEvents: false,
    directUrl: false,
    organizationDefaultCalendarLogo: '',
};

export const AppContext = createContext<AppContextState>(contextInitialValues);
export const useApp = (): AppContextState => useContext(AppContext);

const isDateValid = (dateStr: string) => {
    return !isNaN(new Date(dateStr).getTime());
};

const AppProvider: FC = ({ children }: any) => {
    const [initializingCalendar, setInitializingCalendar] = useState(true);
    const [initializingLocalizations, setInitializingLocalizations] = useState(true);
    const [events, setEvents] = useState<IEvent[]>(contextInitialValues.events);
    const [contentView, setContentView] = useState<CalendarView>(contextInitialValues.contentView);
    const [contentViews, setContentViews] = useState<CalendarView[]>(contextInitialValues.contentViews);
    const [layouts, setLayouts] = useState<Layouts>(contextInitialValues.layouts);
    const [direction, setDirection] = useState<SortDirection>(contextInitialValues.direction);
    const [filterType, setFilterType] = useState<FilterType>(contextInitialValues.filterType);
    const [datetimeFormat, setDatetimeFormat] = useState(contextInitialValues.datetimeFormat);
    const [eventsDefaultImage, setEventsDefaultImage] = useState(contextInitialValues.eventsDefaultImage);
    const [organizationDefaultCalendarLogo, setOrganizationDefaultCalendarLogo] = useState(
        contextInitialValues.organizationDefaultCalendarLogo
    );
    const [changedLanguage, setLanguage] = useState(null);
    const [isStaticTagList, setIsStaticTagList] = useState(false);
    const [staticLocationList, setStaticLocationList] = useState(false);
    const [theme, setTheme] = useState(null);
    const [customLocalizations, setCustomLocalizations] = useState(null);
    const [directUrl, setDirectUrl] = useState(contextInitialValues.directUrl);

    const [embedCalendarId] = useState<string | null>(() => {
        // Embedded id from backend script embed-calendar.js
        let embedCalendarId = window?.calendar_id;
        if (!embedCalendarId) {
            // Secondary way to embed id
            // Use calendar url param for embedding ?calendar=uuid
            const urlParams = new URLSearchParams(window.location.search);

            // URLSearchParams get returns null if param not set
            embedCalendarId = urlParams.get('calendar');
        }
        return embedCalendarId;
    });
    const isEmbedded = !!embedCalendarId;
    const embedLanguage = window?.calendar_language;
    // Get calendar
    // Use embedCalendarId to determine hardcoded rest url
    // TS bug? embedCalendarId can be null here, but TS shows only string type
    const calendarId = embedCalendarId ?? window.location.pathname.split('/')?.[2];
    const { result: calendarResult, error }: A1Calendar = useA1('calendars/' + calendarId, {
        skip: calendarId === null,
        embedUrl: isEmbedded,
    });

    const calendar = calendarResult?.data;
    const foundTags = contextInitialValues.foundTags;
    const availableLocations = contextInitialValues.availableLocations;

    // Create events query params from calendar filters
    const calendarEventFilters: QueryParams = {};
    if (filterType.textField !== '') {
        calendarEventFilters.search = filterType.textField;
    }
    if (filterType.tags.length > 0) {
        calendarEventFilters.tags = [];
        Array.from(foundTags).forEach((tag) => {
            if (
                filterType.tags.includes(
                    (tag.display_name !== null && tag.display_name !== '' ? tag.display_name : tag.key).toLowerCase()
                )
            ) {
                calendarEventFilters.tags.push(String(tag.id));
            }
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.size > 0) {
        const eventTags = urlParams.get('event_tag');
        if (eventTags) {
            const formattedTags = eventTags.split(',');
            filterType.tags = formattedTags;
        }

        const eventCity = urlParams.get('event_city');
        if (eventCity) {
            const formattedCity = eventCity.split(',');
            formattedCity.forEach((city) => {
                filterType.locations.add(city);
            });
        }

        const eventStartDate = urlParams.get('event_start_date');
        if (eventStartDate && isDateValid(eventStartDate)) {
            filterType.dateRange[0] = new Date(eventStartDate);
            filterType.filterBy = 'date-range';
        }

        const eventEndDate = urlParams.get('event_end_date');
        if (eventEndDate && isDateValid(eventEndDate)) {
            filterType.dateRange[1] = new Date(eventEndDate);
            filterType.filterBy = 'date-range';
        }
    }

    if (filterType.locations.size > 0) {
        calendarEventFilters.location = Array.from(filterType.locations);
    }
    // Separate from/to handling for calendar view
    if (contentView === CONTENT_VIEW.Calendar) {
        // Date range comes from changing visible month. By default we show current date events
        if (filterType.dateRange[0] !== null && filterType.dateRange[1] !== null) {
            calendarEventFilters.from = eventillaDate(filterType.dateRange[0]);
            calendarEventFilters.to = eventillaDate(filterType.dateRange[1]);
        } else {
            calendarEventFilters.from = eventillaDate(startOfMonth(new Date()));
            calendarEventFilters.to = eventillaDate(endOfMonth(new Date()));
        }
    } else {
        calendarEventFilters.direction = direction;
        calendarEventFilters.sort_by = 'starts';
        if (filterType.filterBy === FILTER_BY.AllEvents && calendar?.event_filters.past_in_days) {
            const from = eventillaDate(
                startOfDay(subDays(new Date(), Number(calendar?.event_filters.past_in_days ?? 0)))
            );
            calendarEventFilters.from = from;
            calendarEventFilters.to = null;
        } else if (filterType.filterBy === FILTER_BY.UpcomingEvents) {
            calendarEventFilters.from = eventillaDate(startOfMinute(new Date()));
            calendarEventFilters.to = null;
        } else if (filterType.filterBy === FILTER_BY.DateRange && filterType.dateRange.length === 2) {
            calendarEventFilters.from = filterType.dateRange[0]
                ? eventillaDate(startOfDay(filterType.dateRange[0]))
                : null;
            calendarEventFilters.to = filterType.dateRange[1] ? eventillaDate(endOfDay(filterType.dateRange[1])) : null;
        }
    }
    const { result: filterResults }: A1Filters = useA1('calendars/' + calendarId + '/filter_resources', {
        query: {
            language: embedLanguage?.toUpperCase() || (i18next?.language?.toUpperCase() ?? 'EN'),
            ...calendarEventFilters,
        },
        skip: initializingCalendar || initializingLocalizations, // Calendar must be loaded before we load events
        pagination: contentView !== CONTENT_VIEW.Calendar,
        embedUrl: !!embedCalendarId,
    });
    // Get events
    const { result: eventResult, setPage: setEventPage }: A1Events = useA1('calendars/' + calendarId + '/events', {
        query: {
            limit: DEFAULT_LIMIT,
            organization_uuid: calendar?.organization_uuid,
            suborganizations: true,
            language: embedLanguage?.toUpperCase() || (i18next?.language?.toUpperCase() ?? 'EN'),
            ...calendarEventFilters,
        },
        skip: initializingCalendar || initializingLocalizations, // Calendar must be loaded before we load events
        pagination: contentView !== CONTENT_VIEW.Calendar,
        embedUrl: !!embedCalendarId,
    });

    const handleSetFilterType: Dispatch<SetStateAction<FilterType>> = (newFilterType) => {
        setFilterType(newFilterType);
        setEventPage(0);
    };

    useEffect(() => {
        if (eventResult?.data) {
            if (filterResults?.data) {
                filterResults.data.cities.forEach((city) => {
                    if (!staticLocationList) {
                        // Ignore all already available locations, ignored locations and internal locations
                        const fullIgnoredLocations = [
                            ...calendar.layout.locations.ignored,
                            ...calendar.event_filters.locations,
                            ...Array.from(availableLocations),
                        ].map((city) => city.toLowerCase());
                        if (!fullIgnoredLocations.includes(city)) {
                            availableLocations.add(city);
                        }
                    }
                });
                if (!isStaticTagList) {
                    // Ignore all already found tags, ignored tags and internal tags
                    const fullIgnoredTags = [
                        ...calendar.layout.tags.ignored.map((tag) =>
                            (tag.key !== null && tag.key !== '' ? tag.key : '').toLowerCase()
                        ),
                        ...calendar.event_filters.tags.map((tag) =>
                            (tag.key !== null && tag.key !== '' ? tag.key : '').toLowerCase()
                        ),
                        ...Array.from(foundTags).map((tag) =>
                            (tag.key !== null && tag.key !== '' ? tag.key : '').toLowerCase()
                        ),
                    ];
                    filterResults.data.tags?.forEach((tag) => {
                        if (
                            !fullIgnoredTags.includes((tag.key !== null && tag.key !== '' ? tag.key : '').toLowerCase())
                        ) {
                            foundTags.add(tag);
                        } else {
                            foundTags.forEach((existingTag) => {
                                if (existingTag.key === tag.key && existingTag.display_name !== tag.display_name) {
                                    foundTags.delete(existingTag);
                                    foundTags.add(tag);
                                }
                            });
                        }
                    });
                }
            }
            // All but calendar view grows visible event lists
            // Calendar will replace events with new result
            // When starting a new search (first page/offset 0), we also replace all events
            if (contentView === CONTENT_VIEW.Calendar || eventResult.offset === 0) {
                setEvents(eventResult.data);
            } else {
                setEvents((prevEvents) => [...prevEvents, ...eventResult.data]);
            }
        }
    }, [eventResult, filterResults, contentView, isStaticTagList]);

    // Effect to initialize application from calendar settings
    useEffect(() => {
        let cancel = false;
        if (calendar) {
            setDatetimeFormat(calendar.datetime_format);
            if (calendar.layout.enabled_views.length > 0) {
                if (calendar.layout.enabled_views.includes(calendar.layout.default_view)) {
                    setContentView(calendar.layout.default_view);
                } else {
                    setContentView(calendar.layout.enabled_views[0]);
                }
            }
            setContentViews(calendar.layout.enabled_views);

            setLayouts((layout) => ({
                ...layout,
                [CONTENT_VIEW.List]: {
                    eventFields: calendar.layout.list_view.event_fields,
                    filters: calendar.layout.list_view.filters,
                },
                [CONTENT_VIEW.Grid]: {
                    eventFields: calendar.layout.grid_view.event_fields,
                    filters: calendar.layout.grid_view.filters,
                    logoHeight: calendar.layout.grid_view.logo_height,
                },
                [CONTENT_VIEW.Calendar]: {
                    eventFields: calendar.layout.calendar_view.event_fields,
                    filters: calendar.layout.calendar_view.filters,
                },
            }));

            setDirectUrl(calendar.event_filters.direct_url);

            // eslint-disable-next-line no-undef
            const newFilterTypes: Partial<FilterType> = {};

            // Layout tag list (static and absolute list of available tags)
            if (calendar.layout.tags.static_list.length > 0) {
                calendar.layout.tags.static_list.forEach((tag) => foundTags.add(tag));
                setIsStaticTagList(true);
            } else {
                setIsStaticTagList(false);
            }

            // Layout location list (static and absolute list of available locations)
            if (calendar.layout.locations.static_list.length > 0) {
                calendar.layout.locations.static_list.forEach((tag) => availableLocations.add(tag));
                setStaticLocationList(true);
            } else {
                setStaticLocationList(false);
            }

            if (calendar.layout.tags.selected) {
                let newTags = calendar.layout.tags.selected;

                // Allow only layout tags to be selected
                if (calendar.layout.tags.static_list.length > 0) {
                    newTags = newTags.filter((tag) =>
                        calendar.layout.tags.static_list.some((taglistTag) => taglistTag.key === tag.key)
                    );
                } else {
                    // Populate available tags if no static list
                    newTags.forEach((tag) => foundTags.add(tag));
                }
                newFilterTypes.tags = newTags.map((tag) =>
                    (tag.display_name !== null && tag.display_name !== '' ? tag.display_name : tag.key).toLowerCase()
                );
            }

            // Locations
            if (calendar.layout.locations.selected) {
                let newLocations = calendar.layout.locations.selected;

                // Allow only layout tags to be selected
                if (calendar.layout.locations.static_list.length > 0) {
                    newLocations = newLocations.filter((location) =>
                        calendar.layout.locations.static_list.includes(location)
                    );
                } else {
                    // Populate available tags if no layout taglist
                    newLocations.forEach((location) => availableLocations.add(location));
                }
                newFilterTypes.locations = new Set(newLocations);
            }

            if (calendar.events_default_image) {
                setEventsDefaultImage(calendar.events_default_image);
            }

            if (calendar.organization_default_calendar_logo) {
                setOrganizationDefaultCalendarLogo(calendar.organization_default_calendar_logo);
            }

            if (Object.keys(newFilterTypes).length > 0) {
                setFilterType({ ...filterType, ...newFilterTypes });
            }

            // Sort direction
            if (direction !== calendar.event_filters.direction) {
                setDirection(calendar.event_filters.direction);
            }

            // Custom theme
            if (calendar.theme !== null) {
                try {
                    const _calendarTheme = JSON.parse(calendar.theme);
                    setTheme(createTheme(merge(themeEmbed, _calendarTheme)));
                } catch {
                    setTheme(createTheme(themeEmbed));
                    console.error('Calendar theme invalid');
                }
            } else {
                setTheme(createTheme(themeEmbed));
            }

            // Custom localizations
            const customLocalizationsUri = calendar?.organization_uri
                ? createCustomLocalizationUrl(calendar.organization_uri, isEmbedded)
                : null;
            if (customLocalizationsUri !== null) {
                loadCustom(customLocalizationsUri)
                    .then((json) => {
                        if (!cancel) {
                            setCustomLocalizations(json);
                        }
                    })
                    .catch(() => {
                        setCustomLocalizations({});
                    });
            } else {
                setCustomLocalizations({});
            }

            // Calendar initialization done
            setInitializingCalendar(false);
        }

        return () => {
            cancel = true;
        };
    }, [calendar]);

    // Initialize i18n separately to prevent react hook queue problems
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let paramLang = queryParams.get('lang');
        if (paramLang) {
            paramLang = paramLang.replace(/[^A-Za-z]/g, '').toUpperCase();
        }
        if (customLocalizations !== null && calendar) {
            let defaultCalendarLanguage = defaultLanguage;
            if (calendar?.languages.length > 0) {
                defaultCalendarLanguage = calendar.languages[0].toLowerCase();
            }
            if (paramLang) {
                defaultCalendarLanguage = paramLang.toLowerCase();
            }
            const documentLanguage = embedLanguage?.toLowerCase() ?? defaultCalendarLanguage;
            document.documentElement.lang = documentLanguage;
            i18nInit(
                {
                    customLocalizations: customLocalizations,
                    lng: documentLanguage,
                },
                () => {
                    setInitializingLocalizations(false);
                }
            );
        }
    }, [customLocalizations, calendar]);

    return (
        <AppContext.Provider
            value={{
                events,
                contentView,
                contentViews,
                setContentView,
                direction,
                setDirection,
                filterType,
                changedLanguage,
                setLanguage,
                setFilterType: handleSetFilterType,
                setEventPage,
                availableLocations,
                foundTags,
                datetimeFormat,
                directUrl,
                eventsDefaultImage,
                organizationDefaultCalendarLogo,
                layouts,
                translations: calendarResult?.data.translations ?? [],
                loading: initializingCalendar || initializingLocalizations,
                error: calendarId === null ? true : error,
                organizationUri: calendarResult?.data.organization_uri,
                theme: theme,
                hasMoreEvents: eventResult ? eventResult.offset + eventResult.limit < eventResult.total : false,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
